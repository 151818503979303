
:root {
  --header-size: 54px;
  --cover-image-size: 320px;
  --content-padding-h: 94.5px;
  --product-card-w: 300px;
  --product-card-spacing: 60px; /*72px*/
  --product-card-margin-bottom: 28px;
  --product-card-text-height: 60px;
  --product-card-text-padding: 10px;
  --product-card-price-margin-top: -10px;
}


.tmpMsgBoxAnchor {
  margin: 0px auto;
  width: 100vw;
}
.messageBox {
  top: 28px !important;
  padding: 20px 16px 16px !important;
  border-radius: 0px !important;
  z-index: 10 !important;
}
/*
Main Header
*/
/*!Fallback (pour Firefox principalement)*/
.header {
  position: fixed;
  top: 0;
  width: 100vw;
  height: var(--header-size);
  line-height: calc(var(--header-size) - 2px);
  z-index: 11;
  background-color: var(--color-background-darker);
}

@supports ((-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))) {
  .header {
    position: fixed;
    top: 0;
    width: 100vw;
    height: var(--header-size);
    line-height: calc(var(--header-size) - 2px);
    z-index: 11;
    background-color: var(--color-background-dark);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
}

.headerTitle {
  position: absolute;
  font-family: 'Baginda';
  font-size: 52px;
  color: var(--color-white);
  background: transparent;
  margin: -1px 35px 11px;
  padding: 0;
  cursor: default;
}

.headerList {
  position: relative;
  display: flex;
  float: right;
  right: 45px;
  margin: 0;
}

.headerList > li {
  display: flex;
  flex-direction: column;
  margin-left: 42px;
}

.headerList > li > hr {
  height: 2px;
  margin: 0;
  margin-top: -13px;
  background: white;
  pointer-events: none;
  transition: .2s cubic-bezier(0.645, 0.045, 0.355, 1);
  /* not selectable */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.headerListButton {
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 16px;
  font-weight: 700;
  color: var(--color-white);
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  /* not selectable */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.headerListButton > div, .profileHeaderListButton > div {
  border: none;
  border-radius: 50%;
  height: 17px;
  width: 17px;
  font-family: monospace;
  font-size: 13px;
  font-weight: 700;
  color: var(--color-white);
  background: var(--color-info);
  position: relative;
  display: flex;
  justify-content: center;
  top: 10px;
  align-items: center;
  margin-right: -18px;
  letter-spacing: -0.1em;
  padding: 0 0.1em 0 0;
}

.maintenancePage {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 13px;
  font-weight: 400;
  color: #222;
  background-color: #FFF;
  padding: 30px;
  text-align: center;
  height: calc(100vh - 30px * 2);
}

.maintenancePage > h1 {
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 24px;
  font-weight: 700;
}

.maintenancePage > img {
  padding: 0 0 120px 0;
}

.maintenancePageAdminButton {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
  color: var(--color-link);
}

.maintenancePageAdminButton:hover {
  color: var(--color-link-alt);
}

/*
Cover image and title
*/
.coverImage1 {
  position: sticky;
  position: -webkit-sticky;
  top: calc(var(--cover-image-size) * -1 + var(--header-size));
  padding: 0;
  /* background: url("../resources/images/cover/Bandeau 3.jpg"); */
  /* background: url("../resources/images/cover/Bandeau 23 HD.jpg"); */
  background: url("../resources/images/cover/Bandeau 22.jpg");
  background-size: cover;
  background-position: bottom;
  height: var(--cover-image-size);
  width: 100%;
  z-index: 9;
}

.coverImage2 {
  position: sticky;
  position: -webkit-sticky;
  top: calc(var(--cover-image-size) * -1 + var(--header-size));
  padding: 0;
  background: url("../resources/images/cover/Bandeau 25 HD.jpg");
  background-size: cover;
  background-position: bottom center;
  height: var(--cover-image-size);
  width: 100%;
  z-index: 9;
}

.coverImage3 {
  position: sticky;
  position: -webkit-sticky;
  top: calc(var(--cover-image-size) * -1 + var(--header-size));
  padding: 0;
  background: url("../resources/images/cover/Bandeau 28 HD.jpg");
  background-size: cover;
  background-position: left bottom;
  height: var(--cover-image-size);
  width: 100%;
  z-index: 9;
}

.coverTitle {
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
  font-size: calc(min(8vw, 320px));
  bottom: calc(var(--cover-image-size) * -1 + 1.09em);
  letter-spacing: -.05em;
  color: var(--color-background);
  position: relative;
  background: transparent;
  left: -6px;
  margin: 0;
  padding: 0;
  opacity: 1;
  transition: .2s opacity cubic-bezier(0.23, 1, 0.320, 1);
  z-index: 9;
}

.coverTitle1 {
  left: -0.07em;
}

.coverTitle2 {
  left: -0.1em;
}

.coverTitle3 {
  left: -0.025em;
}

/*
Container containing the category header list, category description and products
*/
.contentContainer {
  padding: 0;
  margin: 0px var(--content-padding-h);
}

/*
Category header
*/
.categoryHeaderBox {
  display: flex;
  position: sticky;
  background-color: var(--color-background);
  box-shadow: 0px 3px 16px var(--color-shadow-light);
  height: var(--header-size);
  margin: 0;
  padding: 0;
  z-index: 10;
  top: var(--header-size);
}

/* .switchLabelTheme {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  height: inherit;
  right: 45px;
  z-index: 10;
} */
.switchLabelTheme {
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 10;
  margin: auto;
}

.stickyCategoryHeader {
  position: sticky;
  top: var(--header-size);
  width: 100%;
}

.categoryHeaderList2 {
  position: sticky;
  height: var(--header-size);
  line-height: calc(var(--header-size) - 4px);
  width: inherit;
  display: flex;
  flex-direction: row;
  justify-content: space-between; /*center*/
  align-items: center;
  margin: calc(-1 * var(--header-size)) 0 0 0;
  padding: 0;
  z-index: 10;
  top: var(--header-size);
}

.categoryHeaderList {
  position: sticky;
  height: var(--header-size);
  line-height: calc(var(--header-size) - 4px);
  width: 50%;
  /* min-width: 220px; */
  /* max-width: 320px; */
  /* width: 25vw;
  min-width: 220px;
  max-width: 260px; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  top: var(--header-size);
}

.categoryHeaderList > div {
  justify-items: left;
  display: inline-block;
}

.categoryHeaderList > hr {
  position: absolute;
  height: 3px;
  margin: calc(var(--header-size) / 2) 0 0;
  background: var(--color-text);
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  transition: .3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.categoryHeaderButton {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 18px;
  font-weight: 400;
  padding: 0;
  cursor: pointer;
  color: var(--color-text);
  /* not selectable */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*
Product category description
*/
.categoryDescriptionLink {
  color: var(--color-text-light);
}

.categoryDescriptionLink:hover {
  color: var(--color-text-mid);
}

.categoryDescriptionText {
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 17px;
  letter-spacing: 0.03em;
  line-height: 18px;
  margin: 20px 0;
  padding: 0;
  color: var(--color-text);
  display: flex;
  flex-direction: column;
}

.categoryDescriptionSeparator {
  margin: 20px 0;
  height: 1px;
  transform: scaleY(0.5);
  background: var(--color-text);
}

.categoryDescriptionVideoPlayer {
  width: 300px;
  height: 300px;
  border: none;
  border-radius: 3px;
  margin: 0 auto 10px;
}

.content {
  z-index: -1;
  display: flex;
  flex-direction: column;
}

/*
Product Card Columns
*/
.products {
  display: flex;
  margin: 25px 0px 25px 0px;
}

.productsColumn {
  display: flex;
  flex-direction: column;
  margin: 0px var(--product-card-spacing) 0px 0px;
}

.productsColumn:last-child {
  margin-right: 0px;
}

/*
Product Card
*/
.productCard {
  width: var(--product-card-w);
  margin-bottom: var(--product-card-margin-bottom);
  transition: all 0.25s cubic-bezier(0.215, 0.610, 0.355, 1);
  transition-property: padding, margin;
}

.productCard:hover {
  cursor: pointer;
}

.productImageContainer {
  width: inherit;
  margin: 0;
  padding: 0;
}

.productEmptyImage {
  display: block;
  width: inherit;
  margin: 0;
  padding: 0;
  border-radius: 3px;
}

.productImage {
  display: block;
  width: inherit;
  margin: 0;
  padding: 0;
  transition: opacity 0.25s cubic-bezier(0.215, 0.610, 0.355, 1);
  border-radius: 3px;
  color: rgba(0,0,0,0) !important; /* hides alt text */
}

.productImageSub {
  display: block;
  width: inherit;
  margin: 0;
  padding: 0;
  transition: opacity 0.25s cubic-bezier(0.215, 0.610, 0.355, 1);
  margin-top: calc(var(--product-card-w) * -1);
  object-fit: cover;
  border-radius: 3px;
  color: rgba(0,0,0,0) !important; /* hides alt text */
}

.productName, .productNameExtras {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 16px;
  font-weight: 300;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
}

.productSubTitle, .productSubTitleExtras {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 14px;
  font-weight: 300;
  color: var(--color-text-light);
  margin: -4px 0 0 0;
}

.productId {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 12px;
  font-weight: 300;
  color: var(--color-text-light);
  margin: 0 0 0 auto;
}

.productPrice {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 14px;
  font-weight: 600;
  margin: 3px 0;
}

.productNameCentered {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  margin: var(--product-card-text-padding) 0 0;
  line-height: calc(var(--product-card-text-height) / 2);
  color: var(--color-text);
}

.productIdCentered {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 15px;
  font-weight: 300;
  color: var(--color-text-mid);
  margin: 0 0 0 6px;
}

.productPriceCentered {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 14px;
  font-weight: 600;
  margin: var(--product-card-price-margin-top) 0 0;
  line-height: calc(var(--product-card-text-height) / 2);
  text-align: center;
  color: var(--color-text);
}

.productDescription {
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 16px;
  font-weight: 400;
  margin: 18px 5px 6px;
  text-align: left;
}

.productDescription > span:last-of-type {
  color: var(--color-text-light);
}

.productDisclaimer {
  font-size: 15px;
  letter-spacing: 0.02em;
  color: var(--color-text-light);
}

/*
ProductDetails
*/
.productDetailsBox {
  display: flex;
  flex-direction: column;
  width: 825px;
  height: auto;
}

.productDetailsSpinner {
  margin: 20px auto;
}

.productDetailsImageContainer {
  /* width: inherit; */
  width: fit-content;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  transition: opacity 0.25s cubic-bezier(0.215, 0.610, 0.355, 1);
}

.productDetailsImageArrow {
  width: 40px;
  height: 40px;
  cursor: pointer;
  align-self: flex-end;
  margin: -39px -1px 0 0;
  z-index: 10;
}

.productDetailsImageArrow path, .productDetailsImageArrow circle, .productDetailsImageArrow rect {
  fill: var(--color-background);
}

.productDetailsImageContainer:hover {
  opacity: 0.9;
}

.productDetailsImage {
  display: block;
  width: 375px;
  margin: 0;
  padding: 0;
  transition: opacity 0.2s cubic-bezier(0.215, 0.610, 0.355, 1);
  border-radius: 3px;
  cursor: pointer;
}

.productDetailsImageSub {
  display: block;
  width: 375px;
  margin: 0;
  padding: 0;
  transition: opacity 0.2s cubic-bezier(0.215, 0.610, 0.355, 1);
  object-fit: cover;
  border-radius: 3px;
  cursor: pointer;
}

.productTopLayout {
  display: flex;
  flex-direction: row;
  margin-top: 18px;
  align-items: center;
  justify-content: center;
  width: inherit;
  color: var(--color-text);
}

.productTopLayout > h3 {
  display: block;
  padding: 0 20px;
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 24px;
  font-weight: 800;
}

.productTopLayout > h3 > b {
  display: inline;
  margin: 0;
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 24px;
  font-weight: 400;
  color: var(--color-text-light);
}

.productDescriptionIntent {
  text-align: center;
  margin: 0px 0px 2px;
  padding: 0 18px;
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 17px;
  /* letter-spacing: -0.03em; */
  color: var(--color-text);
}

.productBottomLayout {
  display: flex;
  flex-direction: row;
  width: inherit;
  height: auto;
}

.productLeftLayout {
  display: flex;
  flex-direction: column;
  margin: 18px;
  width: 50%;
  color: var(--color-text);
}

.productRightLayout {
  display: flex;
  flex-direction: column;
  margin: 18px;
  width: 100%;
  color: var(--color-text);
}

.productRightBottomLayout {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
}

.productSizeGuide {
  font-size: 13px;
  font-weight: 200;
  color: var(--color-link);
  margin: auto 15px;
}

.productSizeGuide:hover {
  color: var(--color-link-alt);
}

.productSizeSelectionBox, .productSizeSelectionBoxChrome {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 13px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: space-between;
  padding: 3px 0;
}

.productSizeSelectionBox > p, .productSizeSelectionBoxChrome > p {
  text-align: left;
  margin: auto 10px auto 0;
  line-height: 18px;
}

.productSizeSelectionBox > select {
  margin: 0;
  padding: 1px 0 2px;
  align-self: center;
  width: inherit;
}

.productSizeSelectionBoxChrome > select {
  align-self: center;
  width: fit-content;
  font-size: 13px;
  color: var(--color-text);
  background: inherit;
  margin: 0;
  padding: 3px 4px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 1px 3px var(--color-shadow-mid);
}

.productSizeSelectionBoxButton {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 12px;
  font-weight: 400;
  margin: 0 1px 0 10px;;
  padding: 0 3px 0 0;
  align-self: center;
  color: var(--color-text);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.productSizeSelectionBoxButton:hover {
  padding: 0 0 0 3px;
  color: var(--color-text);
}

.productPackageSection {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 15px;
  font-weight: 700;
  margin: 16px 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.productRightLayout > hr {
  height: 1px;
  margin: 8px 0;
  background: var(--color-text);
  transform: scaleY(0.5);
}

.productSummarySection {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0 18px 0 0;
}

.productSummarySection > hr {
  height: 1px;
  margin: 8px 0;
  background: var(--color-text);
  transform: scaleY(0.5);
}

.productSummarySection > p:first-of-type {
  margin: 0;
}

.productPackageNavigation {
  margin: 0 2px;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: -0.03em;
}

.productPackageNavigationArrow {
  width: 20px;
  height: 20px;
  padding: 0 4px 0 6px;
  cursor: pointer;
  transition: padding 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.productPackageNavigationArrow:hover {
  padding: 0 6px 0 4px;
}

.productPackageBox {
  margin: 0px 0px 0px -18px;
  padding: 10px 17px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 8px var(--color-shadow-light);
}

.productPackageTitle {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.productPackagePriceRange {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 14px;
  font-weight: 600;
}

.productPackageTitle + hr {
  height: 1px;
  width: 100%;
  margin: 5px 0 7px;
  background: var(--color-text-mid);
  transform: scaleY(0.5);
}

.productPackageDescription {
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
}

.productPackageAddExtra {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0 5px;
  cursor: pointer;
  padding-left: 0px;
  transition: padding 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.productPackageAddExtra:hover {
  padding-left: 3px;
}

.productPackageAddIcon {
  width: 13px;
  height: 13px;
  margin: 1px 8px 0 0;
}

.productPackageSummaryItem {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 14px;
  font-weight: 400;
  color: var(--color-text);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 6px 0 0 0;
}

.productRightLayout > p:first-of-type {
  margin: 0;
}

.productPackageSummaryItemIncluded {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 14px;
  font-weight: 300;
  color: var(--color-text-light);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 6px 0 0 0;
}

.productPackageSummaryItemIncluded > span {
  margin: auto 0;
}

.productPackageSummarySize {
  font-size: 13px;
  color: var(--color-text-lighter);
  margin: auto 10px auto auto !important;
}

.productPackageSummaryTotal {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-self: flex-end;
  margin: 0;
  color: var(--color-text);
}

.productPackageSummaryTotalAlt {
  border: 1px solid var(--color-text);
  padding: 2px 10px;
  margin: 0;
}

.productPackageSummaryTotalAmount {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 14px;
  font-weight: 700;
  margin: 0 0 0 8px;
}

.productPackageSummaryUnitPrice {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 14px;
  font-weight: 300;
  color: var(--color-text-mid);
  display: flex;
  /* width: 100%; */
  justify-content: flex-end;
  margin: 0;
}

.productPackageSummaryUnitPriceAmount {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 14px;
  font-weight: 400;
  color: var(--color-text-mid);
  margin: 0 0 0 8px;
}

/*
Extras selection
*/
.extrasBox {
  display: flex;
  flex-direction: column;
  width: 600px;
  max-height: 82vh;
}

.huileEssentielleNotice {
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 16px;
  font-weight: 400;
  color: var(--color-text);
  padding: 10px 30px;
  margin: 0;
}

.huileEssentielleNotice > a {
  color: var(--color-link);
  cursor: pointer;
}

.huileEssentielleNotice > a:hover {
  color: var(--color-link-alt);
}

.extraListContent {
  overflow: scroll;
}

.extraDetailsImage {
  width: 135px;
  height: auto;
  border-radius: 3px;
  object-fit: contain;
  display: flex;
  align-items: flex-start;
  align-self: flex-start;
}

.extraDetailsRightLayout {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 18px;
  width: 100%;
}

.extraDetailsDescription {
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 16px;
  font-weight: 400;
  color: var(--color-text);
  margin: 10px 0;
}

.extraDetailsDescription > a {
  color: var(--color-link);
}

.extraDetailsDescription > a:hover {
  color: var(--color-link-alt);
}

.altSelected {
  background: var(--color-text);
  color: var(--color-white);
  font-weight: 600;
}

/*
AddCode
*/
.addCodeBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 410px;
  height: auto;
  padding: 0 34px;
  color: var(--color-text);
}

.addCodeBox > hr {
  width: 100%;
  height: 1px;
  margin: 0;
  background: var(--color-text);
}

.addCodeDescription {
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 16px;
  font-weight: 400;
  margin: 6px 0 12px;
  text-align: center;
}

.addCodeSubDescription {
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 15px;
  font-weight: 400;
  margin: 14px 0 20px;
  text-align: center;
  color: var(--color-text-mid);
}

.addCodeInput {
  font-family: var(--font-input);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 15px;
  font-weight: 400;
  border: 1px solid var(--color-text);
  border-radius: 3px;
  padding: 2px auto;
  margin: 2px auto;
  width: 150px;
  color: var(--color-text);
  background: var(--color-background);
  text-align: center;
}

/*
SignIn
*/
.signInBox {
  display: flex;
  flex-direction: row;
  width: 720px;
  height: auto;
  color: var(--color-text);
}

.signInBoxMaintenance {
  display: flex;
  flex-direction: row;
  width: 450px;
  height: auto;
  color: var(--color-text);
}

.signInVLayout {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 0 34px;
}

.signInVLayout > form {
  display: contents;
}

.signInTitle {
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 25px;
  font-weight: 700;
  margin: 10px auto 0px auto;
  margin-bottom: 0px;
}

.signInSubTitle {
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 15px;
  font-weight: 400;
  margin: -2px auto;
}

.signInVerticalLine {
  width: 1px;
  margin: 23px 0px;
  padding: 0;
  background: var(--color-text);
  border: none;
}

.signInVerticalLineText {
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 18px;
  font-weight: 400;
  margin: auto -1.4ex;
  background-color: var(--color-background);
  color: var(--color-text);
}

.signInInput {
  font-family: var(--font-input);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 14px;
  font-weight: 400;
  /* letter-spacing: -0.025em; */
  border: 0.5px solid var(--color-text);
  border-radius: 3px;
  padding: 3px 8px;
  margin: 23px 0 0 0;
  color: var(--color-text);
  background: var(--color-background);
  text-align: left;
}

.signInForgottenButton {
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 15px;
  font-weight: 400;
  /* letter-spacing: -0.025em; */
  margin: 4px auto;
  color: var(--color-text-light);
  /* not selectable */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.signInForgottenButton:hover {
  cursor: pointer;
}

/*
Forgotten password
*/
.forgottenPasswordBox {
  display: flex;
  flex-direction: column;
  width: 340px;
  height: auto;
  padding: 0 34px;
  color: var(--color-text);
}

.forgottenPasswordBox > hr {
  height: 1px;
  margin: 0;
  background: var(--color-text);
}

.forgottenPasswordBox > form {
  display: contents;
}

.forgottenPasswordInput {
  font-family: var(--font-input);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 14px;
  font-weight: 400;
  border: 0.5px solid var(--color-text);
  border-radius: 3px;
  padding: 3px 8px;
  margin: 0 0 23px 0;
  color: var(--color-text);
  background: var(--color-background);
  text-align: left;
}

/*
Cart
*/
.cartBox {
  display: flex;
  flex-direction: column;
  width: 580px;
  max-height: 82vh;
  color: var(--color-text);
}

.cartBox > hr {
  height: 1px;
  margin: 1px 0;
  background: var(--color-text);
}

.cartEntries {
  display: block;
  overflow-y: scroll;
}

.cartEntries hr:last-of-type {
  display: none;
}

.cartEntry {
  display: flex;
  margin: 18px 16px;
}

.cartEntryImage {
  width: 135px;
  height: auto;
  object-fit: contain;
  align-self: flex-start;
  border-radius: 3px;
}

.cartEntryInfoLayout {
  display: flex;
  flex-direction: column;
  margin: -4px 0px 0px 16px;
  width: 100%;
}

.cartEntryInfoLayout > hr {
  height: 1px;
  margin: 8px 0;
  background: var(--color-text);
  transform: scaleY(0.5);
}

.cartEntryInfoLayout > hr:first-of-type {
  margin: 4px 0 8px;
}

.cartEntryInfoLayout > p {
  margin: 0;
}

.cartEntryInfoLayout > p.productPackageSummaryItemIncluded, .cartEntryInfoLayout > p.productPackageSummaryItem {
  margin: 6px 0px 0 0;
}

.cartEntryInfoLayout > p:first-of-type {
  margin: 0;
}

.cartActionsRowLayout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cartEntries > hr {
  height: 1px;
  transform: scaleY(0.5);
  background-color: var(--color-text);
  margin: 4px 15px;
  padding: 0;
}

.cartEntryName {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 15px;
  font-weight: 500;
  margin: 2px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cartEntryPrice {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 14px;
  font-weight: 400;
  align-self: center;
}

.cartEntryLabel {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 14px;
  font-weight: 300;
  display: flex;
  /* align-items: center; */
}

.cartEntryLabel > select {
  margin: 0px 0 3px 9px;
  cursor: pointer;
}

.cartEntrySubTotal {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 14px;
  font-weight: 600;
  margin: 0 9px;
}

.cartEntryButton {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.02em;
  padding: 2px 17px 1px 17px;
  height: 1rem;
  border: 1px solid var(--color-text);
  border-radius: 3px;
  color: var(--color-text);
  background-color: var(--color-background);
  /* not selectable */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cartEntryButton:hover {
  cursor: pointer;
  border: 1px solid var(--color-text-mid);
  color: var(--color-text-mid);
}

.cartFooter {
  display: inline-flex;
  flex-shrink: 0;
  width: 100%;
  height: auto;
  padding: 10px 0;
  background-color: var(--color-background);
  box-shadow: 0px -3px 12px var(--color-shadow-light);
  z-index: 15;
}

.cartFooterDescription {
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 13px;
  font-weight: 400;
  margin: auto 16px;
  text-align: left;
  color: var(--color-text-mid);
  width: 50%;
}

.cartFooterInfos {
  margin: auto 16px auto auto;
}

.cartFooterLabel {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 17px;
  font-weight: 300;
  display: inline-flex;
}

.cartFooterPrice {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 15px;
  font-weight: 600;
  margin: auto 0px auto 8px;
}

.cartFooterPriceInfo {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 11px;
  font-weight: 100;
  font-style: italic;
  text-align: right;
  margin-top: -0.3ex;
}

.cartButtonOrder {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.02em;
  text-align: center;
  padding: 3px 18px;
  margin: 15px 16px 15px auto;
  border: none;
  border-radius: 3px;
  color: var(--color-text-white);
  cursor: pointer;
  background-color: var(--color-text);
  transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  /* not selectable */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cartButtonOrder:hover {
  color: var(--color-text-white);
  transform: scale(1.03);
}

.cartEmptyDescription {
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 17px;
  /* letter-spacing: -0.01em; */
  text-align: center;
  margin: 22px 50px;
  padding: 0;
}

.productCategoryNavigation {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 13px;
  text-align: left;
  padding: 10px 0;
  position: sticky;
  top: calc(var(--header-size) * 2);
  align-self: flex-start;
}

.productCategoryNavigation > ul {
  text-align: left;
  list-style: none;
  margin: 0;
  padding-left: 15px;
  border-left: 2px solid var(--color-text);
}

.productCategoryNavigation > ul > li {
  margin: 8px 0;
  padding: 0 0 0 2px;
  cursor: pointer;
  transition: padding 0.25s cubic-bezier(0.19, 1, 0.22, 1);
}

.productCategoryNavigation > ul > li:first-child {
  margin: 0 0 8px 0;
}

.productCategoryNavigation > ul > li:last-child {
  margin: 8px 0 0 0;
}

.productCategoryNavigation > ul > li:hover {
  padding: 0 2px 0 0;
  font-weight: 600;
  color: var(--color-text);
}
/* from: https://stackoverflow.com/questions/5687035/css-bolding-some-text-without-changing-its-containers-size */
.productCategoryNavigation > ul > li::before {
  display: block;
  content: attr(title);
  font-weight: 700;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.categoryNavigationSelectionBox {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 14px;
  width: 45%;
  min-width: 15ch;
  max-width: 43ch;
  height: 1.7em;
  align-self: center;
  align-items: center;
  border: 1px solid var(--color-text);
  border-radius: 1em;
  margin: 0 -1px 0 auto;
  cursor: pointer;
  line-height: 1.1;
  background-color: var(--color-background);
}

.categoryNavigationSelectionBoxCurrent > div {
  appearance: none;
  background-color: transparent;
  color: var(--color-text);
  border: none;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 30px);
  white-space: nowrap;
}

.categoryNavigationSelectionBoxCurrent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: inherit;
  padding: 0px 10px;
}

.categoryNavigationSelectionBoxlist {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 14px;
  position: relative;
  width: 100%;
  height: 0;
  overflow-y: scroll;
  margin: -20px 0 0;
  padding: 0;
  border: none;
  border-radius: 1em;
  color: var(--color-text);
  background: var(--color-background);
  box-shadow: 0 3px 12px var(--color-shadow-light);
  transition: height 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955), margin 0.25s cubic-bezier(0.23, 1, 0.320, 1);
}

.categoryNavigationSelectionBoxlist > p {
  margin: 0;
  padding: 8px 10px 8px;
  line-height: 1.3em;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  /* not selectable */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.categoryNavigationSelectionBoxlist > p:hover {
  padding: 8px 5px 8px 15px;
  background-color: var(--color-text-white);
}

.dropdownSelectButton {
  width: 29px;
  height: 29px;
  border: none;
  border-radius: 0.2em;
  align-self: center;
  margin-right: 10px;
  /* margin-right: -10px; */
  cursor: pointer;
  stroke: var(--color-text);
  fill: var(--color-text);
  transform: scaleX(0.85);
  pointer-events: none;
}

.dropdownSelectButton > g > path {
  stroke: var(--color-text);
  pointer-events: fill;
}

.AboutLogoImage {
  height: 300px;
  width: 300px;
  border-radius: 5px;
  margin: 10px auto 0;
  z-index: 0;
  filter: var(--image-about-logo-filter);
}

.freeMeditationLayout {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.freeMeditation {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin: 0px; */
  margin: 0px 20px 0 10px;
}

.freeMeditation a {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.25s cubic-bezier(0.19, 1, 0.22, 1);
}

.freeMeditation a:hover {
  transform: translateX(5px);
}

.freeMeditationImage {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 0;
}

/* .freeMeditationImage:hover {
  transform: translateY(-5px);
} */

.freeMeditationImageSoundcloud {
  height: 40px;
  width: auto;
  cursor: pointer;
  z-index: 0;
  margin: -45px 0 0 -90px;
}

.freeMeditationImageSoundcloud > g > rect {
  fill: var(--color-background);
}

.freeMeditationImageSoundcloud > g > g > rect {
  fill: var(--color-text);
}
.freeMeditationImageSoundcloud > g > g > circle {
  fill: var(--color-text);
}
.freeMeditationImageSoundcloud > g > g > path {
  fill: var(--color-text);
  stroke: var(--color-text);
}

.freeMeditationText {
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 17px;
  font-weight: 400;
  letter-spacing: -0.02em;
  text-align: left;
  background: var(--color-background);
  min-width: 200px;
  /* padding: 9px 0 10px; */
  margin: 10px 0 0;
  cursor: pointer;
  z-index: 1;
  /* position: absolute; */
}

.sizeGuideText {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 16px;
  font-weight: 400;
  margin: 0 0 15px 0;
  text-align: left;
  color: var(--color-text);
}

.sizeGuideSvg {
  margin: 20px auto;
  width: 400px;
}

.productDescriptionAboutHeadLayout {
  display: flex;
  flex-direction: row;
  margin: 30px auto 0;
}

.productDescriptionAboutHeadLayout > h3 {
  color: var(--color-text);
  align-self: center;
}

@media screen and (max-width: 768px) {
  :root {
    --header-size: 48px;
    --cover-image-size: 200px;
    --content-padding-h: 14.5px;
    --product-card-w: calc(100vw / 2 - 20px * 2);
    --product-card-spacing: 20px;
    --product-card-margin-bottom: 10px;
    --product-card-text-height: 32px;
    --product-card-text-padding: 8px;
    --product-card-price-margin-top: 1px;
  }

  .AboutLogoImage {
    width: 200px;
    height: 200px;
  }

  .freeMeditationLayout {
    justify-content: center;
  }

  .productTopLayout > h4 {
    margin: 0 0 0 0.1em;
  }

  .productRightBottomLayout {
    margin-left: 8px;
  }

  .productSizeSelectionBoxChrome > select {
    padding: 5px 3px;
  }

  .categoryDescriptionVideoPlayer {
    width: 300px;
    height: 300px;
  }

  .cartEmptyDescription {
    font-size: 15px;
  }

  .cartButtonOrder {
    padding: 5px 18px;
  }

  .huileEssentielleNotice {
    font-size: 14px;
  }

  .categoryNavigationSelectionBox {
    min-width: 8ch;
    max-width: 50%;
    width: 35vw;
    font-size: 11px;
  }

  .categoryNavigationSelectionBoxCurrent > div {
    margin: 0;
  }

  .categoryNavigationSelectionBoxlist {
    font-size: 11px;
  }

  .dropdownSelectButton {
    width: 26px;
    height: 26px;
    /* margin-right: 10px; */
  }

  .productNameCentered {
    font-size: 13px;
    margin: var(--product-card-text-padding) 0 0;
    line-height: calc(var(--product-card-text-height) / 2);
  }

  .productPriceCentered {
    font-size: 12px;
    margin: var(--product-card-price-margin-top) 0 0;
    line-height: calc(var(--product-card-text-height) / 2);
  }

  .categoryDescriptionText {
    font-size: 14px;
  }

  .categoryHeaderList {
    position: sticky;
    height: var(--header-size);
    width: inherit;
    min-width: 150px;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: calc(-1 * var(--header-size)) 0 0 0;
    padding: 0;
    z-index: 10;
    top: var(--header-size);
  }

  .categoryHeaderList > div:last-of-type {
    margin: 0 -2px 0 0;
  }

  .categoryHeaderList > div:first-child {
    display: none;
  }

  .categoryHeaderList > hr {
    position: absolute;
    left: 0px;
    margin-top: 1.3em;
  }

  .categoryHeaderButton {
    font-size: 15px;
  }

  .extraDetailsDescription {
    font-size: 14px;
  }

  .switchLabelTheme {
    right: 15px;
  }

  .headerTitle {
    font-size: 44px;
    text-align: center;
    width: inherit;
    margin: auto 0;
  }

  .headerInformationButton {
    width: 36px;
    height: 36px;
    position: absolute;
    left: 12px;
    top: calc((var(--header-size) - 36px) / 2);
    cursor: pointer;
  }

  .headerInformationButton line {
    stroke: var(--color-white);
  }

  /* .headerInformationButton rect, .headerInformationButton path {
    stroke: var(--color-white);
    fill: var(--color-white);
  } */

  .headerBurgerMenu {
    width: 44px;
    height: 44px;
    position: absolute;
    right: 8px;
    top: calc((var(--header-size) - 44px) / 2);
    cursor: pointer;
  }

  .headerBurgerMenu line {
    stroke: var(--color-white);
  }

  .headerBurgerMenuLayout {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    top: var(--header-size);
    height: var(--header-size);
    background: var(--color-text-white);
    box-shadow: 0 2px 12px var(--color-shadow-light);
  }

  .headerList {
    right: 0;
    margin: 0;
    padding: 0;
    align-items: center;
  }

  .headerList > li:first-child {
    margin-left: 0;
  }

  .headerList > li {
    margin-left: 8vw;
  }

  .headerListButton {
    font-size: 14px;
    color: var(--color-text);
    margin: auto 0;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

.headerListButton > div, .profileHeaderListButton > div {
  border: none;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  font-family: monospace;
  font-size: 11px;
  font-weight: 700;
  color: var(--color-white);
  background: var(--color-info);
  position: relative;
  display: flex;
  justify-content: center;
  top: 9px;
  align-items: center;
  margin-right: -18px;
  letter-spacing: -0.1em;
  padding: 0 0.1em 0 0;
}

  .addCodeBox {
    width: 80vw;
    padding: 0 5vw;
  }

  .addCodeDescription {
    font-size: 14px;
  }

  .addCodeSubDescription {
    font-size: 12px;
  }

  .addCodeInput {
    font-size: 13px;
  }

  .signInBox {
    top: 0;
    flex-direction: column;
    width: 90vw;
  }

  .signInVLayout {
    width: auto;
    margin: 0 24px;
  }

  .signInVerticalLine {
    width: inherit;
    height: 1px;
    margin: 10px 0px;
  }

  .signInVerticalLineText {
    display: none;
  }

  .signInTitle {
    font-size: 22px
  }

  .signInSubTitle {
    font-size: 14px;
  }

  .signInInput {
    font-size: 13px;
    margin: 12px 0 0 0;
  }

  .forgottenPasswordInput {
    font-size: 13px;
  }

  .productDetailsBox {
    flex-direction: column;
    width: 90vw;
  }

  .productDetailsImage {
    width: 82vw;
    border-radius: 4px;
  }
  
  .productDetailsImageSub {
    width: 82vw;
    border-radius: 4px;
  }

  .productBottomLayout {
    flex-direction: column;
  }

  .productLeftLayout {
    margin: 4vw;
    width: 82vw;
  }

  .productRightLayout {
    margin: 0 4vw 4vw 4vw;
    width: 82vw;
  }

  .productRightLayout > hr {
    margin: 8px 4px;
  }

  .productRightLayout > p:first-of-type {
    margin: 0 4px;
  }

  .productSummarySection {
    padding: 0 6px;
  }
  
  .productPackageSummaryItemIncluded {
    font-size: 13px;
    margin: 4px 0 0;
  }

  .productPackageSummaryItem {
    font-size: 13px;
    margin: 4px 0 0;
  }

  .productPackageSummaryTotal {
    margin: 0 4px;
  }
 
  .productDescription {
    font-size: 14px;
    margin: 3vw 5px 0vw;
    text-align: left;
  }

  .productDisclaimer {
    font-size: 13px;
  }

  .productName {
    font-size: 15px;
    margin: 0 4px;
    color: var(--color-text);
    font-weight: 500;
  }

  .productNameExtras {
    font-size: 15px;
    margin: 0;
    color: var(--color-text);
    font-weight: 500;
    line-height: 16px;
  }

  .productId {
    font-size: 11px;
  }

  .productSubTitle {
    font-size: 13px;
    margin: -8px 4px 0;
  }

  .productSubTitleExtras {
    font-size: 13px;
    margin: 2px 0 0;
    line-height: 15px;
  }

  .productPackageBox {
    margin: 0px -2px 0px -2px;
  }

  .productPackageSection {
    margin: 16px 4px 0;
  }

  .extrasBox {
    width: 95vw;
    max-height: 80vh;
    margin: 1vh auto 20vh auto !important;
  }

  .extraDetailsImage {
    width: 95px;
    margin: 0 0 0 -10px;
  }

  .forgottenPasswordBox {
    width: calc(90vw - 24px * 2);
    padding: 0 24px;
  }

  .signInForgottenButton {
    margin: 8px auto 12px;
  }

  .cartBox {
    width: 95vw;
  }

  .cartEntryImage {
    border-radius: 5px;
    width: calc(50vw - 20px - 10px);
    margin: 0 auto 10px auto;
  }

  .cartEntryName {
    font-size: 13px;
  }

  .cartEntryInfoLayout {
    margin: 0;
  }

  .cartListEntry {
    padding: 25px 20px 20px 20px !important;
    flex-direction: column !important;
  }

  .cartEntryLabel {
    font-size: 13px;
  }

  .cartFooterLabel {
    font-size: 15px;
  }

  .cartFooterDescription {
    font-size: 12px;
  }

  .productPackageSummaryUnitPrice {
    font-size: 13px;
  }

  .productPackageSummaryUnitPriceAmount {
    font-size: 13px;
  }

  .productPackageSummaryTotal {
    font-size: 13px;
  }

  .productPackageSummaryTotalAmount {
    font-size: 13px;
  }

  :focus {
    outline: none !important;
  }
  :root {
    -webkit-tap-highlight-color: transparent;
  }

  p, span, div {
    -webkit-tap-highlight-color: transparent;
  }
}

@media screen and (max-width: 360px) {
  :root {
    --product-card-text-height: 26px;
    --product-card-text-padding: 8px;
    --product-card-price-margin-top: 4px;
  }

  .productNameCentered {
    font-size: 12px;
    /* margin: 7px 0 0; */
    margin: var(--product-card-text-padding) 0 0;
    line-height: calc(var(--product-card-text-height) / 2);
  }

  .productPriceCentered {
    font-size: 11px;
    margin: var(--product-card-price-margin-top) 0 0;
    line-height: calc(var(--product-card-text-height) / 2);
  }

  .categoryHeaderButton {
    font-size: 13px;
  }
}
