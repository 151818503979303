@import "../resources/fonts/Baginda/BagindaScript.css";

:root {
  --audio-player-size: 74px;
  --meditations-card-w: 610px;
  --meditations-card-spacing: 27px;
}
/*
Profile Header
*/
.profileHeader {
  position: sticky;
  top: 0;
  width: 100%;
  height: var(--header-size);
  line-height: calc(var(--header-size) - 2px);
  background-color: var(--color-background);
  box-shadow: 0 3px 12px var(--color-shadow-mid);
  z-index: 10000;
}

.profileHeaderTitle {
  position: absolute;
  font-family: 'Baginda';
  font-size: 52px;
  color: var(--color-text);
  background: transparent;
  margin: -1px 59px 11px 59px;
  padding: 0;
  cursor: default;
}

.profileHeaderList {
  position: relative;
  float: right;
  right: 45px;
  margin: 0;
}

.profileHeaderList > li {
  display: inline-block;
  margin-left: 42px;
}

.profileHeaderList > li:last-child > div {
  font-weight: 700;
}

.profileHeaderListButton {
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 16px;
  font-weight: 400;
  color: var(--color-text);
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  height: calc(var(--header-size) - 6px);
  /* not selectable */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*
MeditationsCard Columns
*/
.meditationsColumn {
  display: flex;
  min-height: calc(100vh - var(--audio-player-size) - var(--header-size) - var(--meditations-card-spacing));
  flex-direction: column;
  margin: 0px auto calc(var(--audio-player-size) + var(--meditations-card-spacing)) auto;
}

.meditationsColumnSpinner {
  display: flex;
  min-height: calc(100vh - var(--audio-player-size) - var(--header-size) - var(--meditations-card-spacing));
  flex-direction: column;
  margin: 0px auto calc(var(--audio-player-size) + var(--meditations-card-spacing)) auto;
}

.arrowDownIcon {
  margin: 0 auto;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  margin-top: 10px;
  padding-bottom: 5px;
  cursor: pointer;
  transition: padding 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.arrowDownIcon:hover {
  padding-top: 5px;
  padding-bottom: 0px;
}

/*
Meditations Card
*/
.meditationsCard {
  display: flex;
  width: var(--meditations-card-w);
  margin: var(--meditations-card-spacing) auto 0px auto;
  padding: 18px;
  border-radius: 5px;
  box-shadow: 0 2px 18px var(--color-shadow-light);
}

.meditationsCardEmpty {
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 18px;
  font-weight: 400;
  color: var(--color-text-mid);
  text-align: center;
  width: 100%;
  margin: 50px 0;
}

.meditationsImage {
  width: 225px;
  min-height: 225px;
  height: auto;
  /* object-fit: contain; */
  object-fit: cover;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  border-radius: 3px;
}

.meditationsInfoLayout {
  display: flex;
  flex-direction: column;
  margin: 0px 0px 0px 16px;
  width: 100%;
}

.meditationsInfoLayout > hr {
  height: 1px;
  transform: scaleY(0.5);
  background-color: var(--color-text);
  margin: 10px 0 1px 0;
  padding: 0;
}

.meditationsName {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 15px;
  font-weight: 300;
  color: var(--color-text);
}

.meditationsId {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 12px;
  font-weight: 300;
  color: var(--color-text-mid);
  text-align: right;
  line-height: 25px;
  margin-top: -2em;
}

.meditationsCode {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 14px;
  font-weight: 600;
  margin-top: -0.3ex;
  color: var(--color-text);
}

.meditationsList {
  display: block;
  overflow-y: scroll;
  margin: 0;
  padding: 0;
}

.meditationsGrid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(7, 55px);
  grid-template-rows: repeat(5, 45px);
  margin: 0;
  padding: 0;
}

.meditationGridHeader {
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  color: var(--color-text);
  background: var(--color-background);
}

.meditationGridItem {
  font-family: var(--font-input);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid var(--color-text);
  border-radius: 50%;
  color: var(--color-text);
  background: var(--color-background);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-property: color, background;
}

.meditationGridItem:hover {
  color: var(--color-background);
  background: var(--color-text);
  font-weight: 700;
  cursor: pointer;
}

.meditationGridItemSelected {
  font-family: var(--font-input);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid var(--color-text);
  border-radius: 50%;
  color: var(--color-background);
  background: var(--color-text);
  font-weight: 700;
  cursor: pointer;
}

.meditationGridItemUnavailable {
  font-family: var(--font-input);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid var(--color-text-lighter);
  border-radius: 50%;
  color: var(--color-text-lighter);
  background: var(--color-background);
  /* not selectable */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.meditationItem {
  display: flex;
  padding: 8px 4px;
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 16px;
  font-weight: 400;
  color: var(--color-text);
  text-align: left;
  padding-left: 0px;
  transition: padding 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.meditationItem:hover {
  padding: 8px 10px;
  font-weight: 700;
  cursor: pointer;
}

.meditationItem + hr {
  height: 1px;
  transform: scaleY(0.5);
  background-color: var(--color-text-lighter);
  margin: 0;
  padding: 0;
}

.meditationItemSelected {
  display: flex;
  padding: 8px 4px;
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 16px;
  font-weight: 700;
  color: var(--color-text);
  text-align: left;
  padding-left: 10px;
  cursor: pointer;
}

.meditationItemSelected + hr {
  height: 1px;
  transform: scaleY(0.5);
  background-color: var(--color-text-lighter);
  margin: 0;
  padding: 0;
}

.returnButton {
  position: absolute;
  display: inline-flex;
  align-items: center;
  height: 100%;
  margin: auto;
  left: 24px;
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 12px;
  font-weight: 400;
  color: var(--color-text);
  text-align: left;
  transition: padding 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  cursor: pointer;
  z-index: 20;
  /* not selectable */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.returnButton:hover {
  padding-left: 4px;
  font-weight: 500;
  letter-spacing: -0.017em;
  cursor: pointer;
}

.returnButtonIcon {
  width: 25px;
  height: 25px;
  transform: rotate(90deg);
  margin: auto;
  margin-right: 2px;
}

.downloadButton {
  position: relative;
  display: inline-flex;
  margin: -20px
   auto auto 0px;
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 12px;
  font-weight: 600;
  color: var(--color-text);
  text-align: left;
  transition: padding 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  cursor: pointer;
  z-index: 20;
  /* not selectable */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*
AddCode
*/
.addCodeTextInput {
  font-family: var(--font-input);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 12px;
  font-weight: 900;
  border: 1px solid var(--color-text);
  border-radius: 3px;
  padding: 3px 3px;
  margin: 2px auto;
  width: fit-content;
  color: var(--color-text);
  background: transparent;
  text-align: center;
}

.addCodeTextInput::placeholder {
  color: var(--color-text);
  font-weight: 400;
  letter-spacing: -0.01em;
}

/*
AudioPlayerBox
*/
.audioPlayerBox {
  position: fixed;
  bottom: 0;
  height: var(--audio-player-size);
  width: 100%;
  background-color: var(--color-background);
  box-shadow: 0 -3px 12px var(--color-shadow-mid);
  z-index: 10000;
}

/*
AudioPlayer
*/
.audioPlayerLayout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--color-background);
  width: 100%;
  height: inherit;
  margin: auto;
}

.audioPlayerName {
  position: relative;
  font-family: var(--font-input);
  letter-spacing: -0.03em;
  font-size: 13px;
  color: var(--color-text);
  background: transparent;
  border: none;
  pointer-events: none;
  margin-top: 0.9em;
  height: inherit;
  cursor: default;
  z-index: 10;
  /* not selectable */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.audioPlayerDuration {
  position: relative;
  font-family: var(--font-input);
  letter-spacing: -0.03em;
  font-size: 12px;
  color: var(--color-text);
  background: transparent;
  border: none;
  pointer-events: none;
  margin-bottom: 1.1em;
  cursor: default;
  z-index: 10;
  /* not selectable */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.audioPlayerCurrentTime {
  position: relative;
  font-family: var(--font-input);
  letter-spacing: -0.03em;
  font-size: 12px;
  color: var(--color-text-light);
  background: transparent;
  border: none;
  pointer-events: none;
  margin-bottom: 3px;
  cursor: default;
  z-index: 10;
  /* not selectable */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.audioPlayerSubLayout {
  /* background: transparent; */
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: inherit;
  position: absolute;
}

.audioPlayerPlayButton {
  background: transparent;
  width: 24px;
  height: 24px;
  padding: 5px;
  border: none;
  border-radius: 0.2em;
  cursor: pointer;
  stroke: var(--color-text);
  fill: var(--color-text);
  transform: translateY(-0.5px);
  transition: transform 0.2s cubic-bezier(0.19, 1, 0.22, 1);
}

.audioPlayerPlayButton:hover {
  transform: scale(1.1);
}

.audioPlayerBar {
  display: inline-flex;
  z-index: 1; /* Needed */
  background: var(--color-text-lighter);
  border: 4px solid var(--color-background);
  border-radius: 1em;
  height: 6px;
  width: 560px;
  margin: 0px 13px;
  box-shadow: inset 0 1px 3px var(--color-shadow-light);
  cursor: pointer;
}

.audioPlayerBarFill {
  position: absolute;
  z-index: 2; /* Needed */
  background: var(--color-text);
  border-radius: 0.2em;
  height: 6px;
  width: 4px;
  cursor: pointer;
}

.audioPlayerBarCursor {
  background: var(--color-text);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 0px;
  height: 0px;
  margin: 2.5px -2px 0 0;
  transition: all 0.1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-property: width, height, margin;
}

.audioPlayerBar:hover > .audioPlayerBarCursor {
  width: 12px;
  height: 12px;
  margin: -3px 0 0 -6px;
}

.audioPlayerBarFill:hover + .audioPlayerBarCursor {
  width: 12px;
  height: 12px;
  margin: -3px 0 0 -6px;
}

/*
AudioPlayer volume
*/
.audioPlayerVolumeLayout {
  background: transparent;
  border: none;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: right;
  height: inherit;
  position: absolute;
  right: 24px;
}

.audioPlayerVolumeBar {
  display: inline-flex;
  z-index: 1; /* Needed */
  background: var(--color-text-lighter);
  border: 4px solid var(--color-background);
  border-radius: 1em;
  height: 6px;
  width: 90px;
  box-shadow: inset 0 1px 3px var(--color-shadow-light);
  cursor: pointer;
}

.audioPlayerVolumeBarCursor {
  background: var(--color-text);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 0px;
  height: 0px;
  margin: 2.5px -2px 0 0;
  transition: all 0.1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-property: width, height, margin;
}

.audioPlayerVolumeBar:hover > .audioPlayerVolumeBarCursor {
  width: 10px;
  height: 10px;
  margin: -2px 0 0 -4px;
}

.audioPlayerBarFill:hover + .audioPlayerVolumeBarCursor {
  width: 10px;
  height: 10px;
  margin: -2px 0 0 -4px;
}

.audioPlayerVolumeButton {
  padding: 5px;
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 0.2em;
  margin-bottom: 1px;
  margin-right: 6px;
  cursor: pointer;
  stroke: var(--color-text);
  fill: var(--color-text);
  transform: translateY(-0.5px);
  transition: transform 0.2s cubic-bezier(0.19, 1, 0.22, 1);
}

.audioPlayerVolumeButton > g > path {
  stroke: var(--color-text);
}

.audioPlayerVolumeButton:hover {
  transform: scale(1.1);
}

/*
Modify Account
*/
.modifyAccountBox {
  display: flex;
  flex-direction: column;
  width: 330px;
  height: auto;
  padding: 0 34px;
  color: var(--color-text);
}

.modifyAccountTitle {
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 22px;
  font-weight: 700;
  margin: 10px auto;
}

.modifyAccountTitleSub {
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 19px;
  font-weight: 700;
  margin: 10px auto 10px 2px;
}

.modifyAccountCenter {
  margin: 10px auto;
  font-size: 16px;
}

.modifyAccountBox > hr {
  height: 1px;
  margin: 0;
  background: var(--color-text);
}

.modifyAccountBox > form + hr {
  height: 1px;
  margin: 0 0 5px 0;
  background: var(--color-text-light);
  transform: scaleY(0.5);
}

.modifyAccountBox > form {
  display: contents;
}

.modifyAccountInput {
  font-family: var(--font-input);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 14px;
  font-weight: 400;
  border: 0.5px solid var(--color-text);
  border-radius: 3px;
  padding: 3px 8px;
  margin: 0 0 15px;
  color: var(--color-text);
  background: var(--color-background);
  text-align: left;
}

.modifyAccountNotice {
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 15px;
  font-weight: 400;
  margin: 0 0 10px 0;
  text-align: center;
  color: var(--color-text-mid);
}

/*
*/
.modifyAccountAccountDeletionBox {
  display: flex;
  flex-direction: column;
  width: 290px;
  height: auto;
  padding: 0 34px;
}

.modifyAccountAccountDeletionBox > form {
  display: contents;
}

/*
Orders
*/
.ordersBox {
  display: flex;
  flex-direction: column;
  width: 472px;
  max-height: 604px;
}

.ordersBox > hr {
  height: 1px;
  margin: 1px 0;
  background: var(--color-text);
}

.ordersEntries {
  display: block;
  overflow-y: scroll;
}

.ordersEntries hr:last-of-type {
  display: none;
}

.ordersEntry {
  display: flex;
}

.ordersEntry + hr {
  height: 1px;
  transform: scaleY(0.5);
  background-color: var(--color-text-lighter);
  margin: 12px 0;
  padding: 0;
}

.ordersEntryHeaderLayout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
}

.ordersEntryHeaderLayout + hr {
  height: 2px;
  /* transform: scaleY(1); */
  background-color: var(--color-text);
  margin: 6px 0 14px;
  padding: 0;
}

.ordersEntryOrderId {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0;
}

.ordersEntryId {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 14px;
  font-weight: 700;
  margin: 0 4px;
}

.ordersEntryDate {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 12px;
  font-weight: 300;
  color: var(--color-text-mid);
  margin: 0 10px 0 auto;
  align-self: center;
}

.ordersEntryStatusIndicator {
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 16px;
  font-weight: 700;
  position: relative;
  align-self: center;
  padding: 2px 16px;
  margin: 0 0 1px;
  border: none;
  border-radius: 3px;
  color: var(--color-white);
  cursor: pointer;
  background-color: var(--color-text);
}

.ordersEntryLabel {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 14px;
  font-weight: 300;
  display: flex;
  margin: 0 32px 0 0;
  color: var(--color-text-mid);
}

.ordersEntryQuantity {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 0 8px;
  color: var(--color-text);
}

.ordersEntryLabelAlt {
  margin: auto 0 auto auto;
}

.ordersEntrySubTotal {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 14px;
  font-weight: 600;
  /* margin: 0 9px; */
  margin: 0 0 0 9px;
}

.ordersQuantity {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 14px;
  font-weight: 600;
  margin: 0 9px; 
}

.ordersEntryButton {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 11px;
  font-weight: 400;
  padding: 1px 17px 0px 17px;
  margin: 0px 0px 0px auto;
  border: 1px solid var(--color-text);
  border-radius: 3px;
  color: var(--color-text);
  background-color: var(--color-background);
  /* not selectable */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ordersEntryButton:hover {
  cursor: pointer;
  border: 1px solid var(--color-text-mid);
  color: var(--color-text-mid);
}

.ordersFooter {
  display: inline-flex;
  flex-shrink: 0;
  width: 100%;
  height: auto;
  background: var(--color-background);
  box-shadow: 0px -3px 12px var(--color-shadow-light);
  z-index: 15;
}

.ordersFooterDescription {
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  margin: 15px 16px;
  color: var(--color-text-mid);
  width: 100%;
}

.ordersEmptyDescription {
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 17px;
  /* letter-spacing: -0.01em; */
  text-align: center;
  margin: 16px 25px 6px;
  padding: 0;
}

.ordersEmptyDescription + hr {
  height: 1px;
  transform: scaleY(0.5);
  background-color: var(--color-text-light);
  margin: 0 34px;
  padding: 0;
}

.ordersContactMailTitle {
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin: 10px auto 0;
  color: var(--color-text);
  width: 100%;
}

.ordersContactMail {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.02em;
  text-align: center;
  margin: -2px auto 10px;
  color: var(--color-text-mid);
  width: 100%;
}

@media screen and (max-width: 990px) {
  .audioPlayerBar {
    width: 46vw;
  }

  .audioPlayerVolumeBar {
    width: 10vw;
  }

  .profileHeaderList > li {
    margin-left: calc(2.5vw);
  }

  .profileHeaderTitle {
    margin: -1px 30px 11px 30px;
  }
}

@media screen and (max-width: 768px) {
  .profileHeaderTitle {
    font-size: 44px;
    text-align: center;
    width: inherit;
    margin: auto 0;
  }

  .addCodeTextInput {
    margin: 0profileHeaderListpx auto 4px;
  }

  .profileHeaderBurgerMenu {
    width: 44px;
    height: 44px;
    position: absolute;
    right: 8px;
    top: calc((var(--header-size) - 44px) / 2);
    cursor: pointer;
  }

  .profileHeaderBurgerMenu line {
    stroke: var(--color-text);
  }

  .profileHeaderBurgerMenuLayout {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    top: var(--header-size);
    height: calc(var(--header-size) * 2);
    background: var(--color-background);
    box-shadow: 0 4px 12px var(--color-shadow-light);
  }

  .profileHeaderList {
    right: 0;
    margin: 0;
    padding: 0;
    align-items: center;
  }

  .profileHeaderList > li:first-child {
    margin-left: 0;
  }

  .profileHeaderList > li {
    margin-left: 30px;
  }

  .profileHeaderListButton {
    font-size: 14px;
    color: var(--color-text);
    margin: auto 0;
  }

  .meditationsGrid {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(7, auto);
    grid-template-rows: repeat(5, 45px);
    margin: 10px 0;
    padding: 0;
    justify-items: center;
  }

  .returnButton {
    left: 8px;
    font-weight: 600;
  }

  .returnButtonIcon {
    width: 42px;
    height: 42px;
  }

  .meditationsCardEmpty {
    font-size: 13px;
    width: 80%;
    margin: 50px auto;
  }

  .audioPlayerBar {
    width: 67vw;
  }

  .audioPlayerName {
    font-size: 12px;
  }

  .audioPlayerBox {
    width: 100vw;
  }

  .audioPlayerCurrentTime {
    width: 34px;
  }

  .meditationsCard {
    width: calc(100vw - 18px * 2);
    margin: -5px 0 0 0;
    padding: 23px 18px 18px;
    background: var(--color-background);
  }

  .meditationsInfoLayout {
    height: max-content !important;
  }

  .meditationsImage {
    width: 100px;
    min-height: 100px;
    align-self: flex-start;
  }

  .meditationsName {
    font-size: 14px;
  }

  .meditationsId {
    font-size: 12px;
  }

  .meditationsCode { 
    font-size: 12px;
  }

  .meditationGridItem {
    font-size: 12px;
  }

  .meditationGridItemSelected {
    font-size: 12px;
  }

  .meditationGridItemUnavailable {
    font-size: 12px;
  }

  .meditationItem {
    font-size: 14px;
  }

  .meditationItemSelected {
    font-size: 14px;
    padding: 8px 0px;
  }

  .modifyAccountBox {
    width: calc(90vw - 34px * 2);
  }

  .modifyAccountTitleSub {
    font-size: 14px;
  }

  .modifyAccountInput {
    font-size: 13px;
  }

  .ordersEntryOrderId {
    font-size: 13px;
  }

  .ordersEntryId {
    font-size: 13px;
  }

  .ordersEntryStatusIndicator {
    font-size: 13px;
  }

  .ordersFooter {
    z-index: 101;
  }

  .ordersContactMailTitle {
    padding: 10px 0 0 0;
    margin: 0 auto;
    z-index: 100;
    box-shadow: 0 -2px 6px var(--color-shadow-light);
  }
}