@font-face {
	font-family: "Baginda";
	src: url('./BagindaScript.otf');
}

@font-face {
	font-family: "Baginda";
	font-style: oblique;
	src: url('./BagindaScriptOblique.otf');
}
