.orderEntriesLeftMenu {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-weight: 400;
  font-size: 13px;
  color: var(--color-text);
  margin: 35px 0;
  float: left;
  right: calc(100vw - (100vw - 810px) / 2 + 30px);
  width: max-content;
  position: fixed;
}

.orderEntriesLeftMenu > ul {
  text-align: right;
  list-style: none;
  margin: 0;
  padding-right: 15px;
  border-right: 2px solid var(--color-text);
}

.orderEntriesLeftMenu > ul > li {
  margin: 8px 0;
  padding: 0 0 0 2px;
  cursor: pointer;
  transition: padding 0.25s cubic-bezier(0.19, 1, 0.22, 1);
}

.orderEntriesLeftMenu > ul > li:first-child {
  margin: 0 0 8px 0;
}

.orderEntriesLeftMenu > ul > li:last-child {
  margin: 8px 0 0 0;
}

.orderEntriesLeftMenu > ul > li:hover {
  padding: 0 2px 0 0;
  font-weight: 600;
  color: var(--color-text);
}
/* from: https://stackoverflow.com/questions/5687035/css-bolding-some-text-without-changing-its-containers-size */
.orderEntriesLeftMenu > ul > li::before {
  display: block;
  content: attr(title);
  font-weight: 700;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.groupEntriesRightButton {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-weight: 600;
  font-size: 13px;
  color: var(--color-text);
  margin: 35px 0;
  left: calc(810px + (100vw - 810px) / 2 + 23px);
  width: max-content;
  position: fixed;
  padding: 0 2px 0 0;
  cursor: pointer;
  transition: padding 0.25s cubic-bezier(0.19, 1, 0.22, 1);
}

.groupEntriesRightButton:hover {
  padding: 0 0 0 2px;
  color: var(--color-text);
}

.orderEntriesLayout {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0;
  width: 100vw;
  min-height: 400vh;
}

.orderEntryItemBox {
  width: 810px;
  border: none;
  border-radius: 5px;
  color: var(--color-text);
  background: var(--color-background);
  box-shadow: 0 2px 12px var(--color-shadow-light);
  display: flex;
  flex-direction: column;
  margin: -10px 0 0;
  padding: 10px 0 0;
}

.orderEntriesLayout > .orderEntryItemBox:first-child {
  margin: 0;
  padding: 0;
}

.orderEntryItemHeader {
  width: 100%;
  height: 45px;
  border: none;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  transition: background 0.25s cubic-bezier(0.19, 1, 0.22, 1);
  margin: -10px 0 0;
  padding: 10px 0 0;
}

.orderEntriesLayout > .orderEntryItemBox:first-child > .productEntryOuterHeader > .orderEntryItemHeader {
  margin: 0;
  padding: 0;
}

.orderEntriesLayout > .orderEntryItemBox:first-child > .orderEntryItemHeader {
  margin: 0;
  padding: 0;
}

.orderEntryItemHeader:hover {
  background: var(--color-hover);
}

.orderEntryItemCell {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-weight: 500;
  font-size: 13px;
  text-align: right;
  width: 150px;
  margin: auto 16px;
}

.orderEntryItemCellStatus {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-weight: 600;
  font-size: 12px;
  letter-spacing: -0.01em;
  color: var(--color-text);
  border: 1px solid var(--color-text);
  border-radius: 3px;
  text-align: center;
  width: 120px;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1);
}

.orderEntryItemCellStatus:hover {
  color: var(--color-text-light) !important;
  border: 1px solid var(--color-text-light) !important;
}

.orderEntryItemExpanded {
  display: none;
  align-items: center;
  flex-direction: column;
  padding: 10px;
}

.orderEntryItemExpandedRecipient {
  background: var(--color-background);
  border-radius: 10px;
  margin: 20px auto;
  padding: 20px;
  box-shadow: 0 4px 12px var(--color-shadow-light);
  display: flex;
  flex-direction: column;
  width: 580px;
  position: relative;
}

.productEntryItemCell {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  color: inherit;
  margin: auto 16px;
}

.productEntryItemCell > b {
  font-weight: 500;
  color: inherit;
}

.recipientName {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-weight: 600;
  font-size: 14px;
  letter-spacing: -0.01em;
  color: var(--color-text);
  margin: 0;
}

.recipientLineLayout {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-weight: 400;
  font-size: 13px;
  color: var(--color-text);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  line-height: 20px;
}

.recipientAddress {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-weight: 400;
  font-size: 13px;
  color: var(--color-text);
  margin: 0;
  line-height: 20px;
}

.recipientLink {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-weight: 500;
  font-size: 13px;
  color: var(--color-link);
  margin: 0;
  line-height: 20px;
}

.recipientLink:hover {
  color: var(--color-link-alt);
}

.orderActionsLayout {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 620px;
  position: relative;
  margin: 0 0 20px;
}

.orderActionButton {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 13px;
  font-weight: 600;
  position: relative;
  align-self: flex-start;
  width: fit-content;
  padding: 3px 20px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  /* not selectable */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.orderActionButton:hover {
  transform: scale(1.03);
}

.orderActionsNotice {
  position: relative;
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 13px;
  font-weight: 500;
  margin: 0 85px 15px;
  padding: 3px 10px;
  color: var(--color-error);
  border: 1px solid var(--color-error);
  border-radius: 3px;
}

.buttonConfirm {
  color: var(--color-background);
  background-color: var(--color-text);
  margin: 0;
  /* not selectable */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.buttonReject {
  color: var(--color-white);
  background-color: var(--color-error);
  margin: 0 0 0 18px;
  /* not selectable */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.orderActionTextField {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 13px;
  font-weight: 600;
  border: 1px solid var(--color-text);
  border-radius: 3px;
  padding: 0px 8px;
  width: 200px;
  color: var(--color-text);
  background: var(--color-background);
  text-align: left;
}

.orderActionTextField::placeholder {
  font-weight: 400;
}

.orderActionLabel {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 13px;
  font-weight: 300;
  padding: 0px 8px;
  margin: auto;
  color: var(--color-text-mid);
  text-align: right;
}

.orderEmptyMsgBoxAnchor {
  margin: 0px auto;
  width: 630px;
}

.cancelOrderModalBox {
  display: flex;
  flex-direction: column;
  width: 430px;
  height: auto;
  padding: 0 34px;
}

.cancelOrderModalBox > form {
  display: contents;
}

.cancelOrderTextArea {
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 17px;
  font-weight: 400;
  /* letter-spacing: -0.025em; */
  border: 0.5px solid var(--color-text);
  border-radius: 3px;
  padding: 3px 8px;
  margin: 0 0 15px;
  color: var(--color-text);
  background: var(--color-background);
  text-align: left;
  resize: vertical;
  height: 100px;
  min-height: 30px;
  max-height: 60vh;
}

.cancelOrderNotice {
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 16px;
  font-weight: 400;
  margin: 20px 0 15px;
  text-align: left;
  color: var(--color-text);
}

.adminProductActionsLayout {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 0 15px;
}

.selectionBox, .selectionBoxChrome {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 13px;
  font-weight: 400;
  width: 100%;
  /* margin: 0 10px; */
  display: flex;
  flex-direction: row;
  padding: 3px 0;
}

.selectionBox > p, .selectionBoxChrome > p {
  width: 64px;
  text-align: left;
  margin: 0;
  line-height: 18px;
}

.selectionBox > select {
  margin: 0 6px;
  padding: 1px 0 2px;
  align-self: center;
  width: inherit;
}

.selectionBoxChrome > select {
  align-self: center;
  width: calc(100% - 64px - 1px);
  font-size: 12px;
  color: var(--color-text);
  background: inherit;
  margin: 0;
  padding: 2px 3px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 1px 3px var(--color-shadow-mid);
}

.selectionBoxButton {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 12px;
  font-weight: 400;
  margin: 0 1px 0 10px;;
  padding: 0 3px 0 0;
  align-self: center;
  color: var(--color-text);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.selectionBoxButton:hover {
  padding: 0 0 0 3px;
  color: var(--color-text);
}

.adminProductLineLayout {
  display: flex;
  flex-direction: row;
}

.adminProductIdDisplay {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  align-self: center;
  color: var(--color-text-light);
  border-radius: 3px;
  height: max-content;
}

.adminProductFormLayout {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.adminProductPriceInput {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: var(--color-text);
  background: inherit;
  border: 1px solid var(--color-text-lighter);
  border-radius: 3px;
  padding: 1px 4px 1px 8px;
  height: 17px;
  width: 50px;
  margin: 2px 0 0 0;
  text-align: right;
}

.adminProductPriceInputLayout {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 13px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  margin: 0px auto auto 0px;
}

.adminProductPriceInputLayout > p {
  width: max-content;
  text-align: right;
  margin: 1px 8px auto auto;
}

.adminProductPriceInputLayout > input + p {
  width: max-content;
  text-align: right;
  font-size: 15px;
  font-weight: 500;
  margin: 0px 4px auto;
}

.adminProductLineLayout > .selectionBox > p {
  margin: 0 6px 0 0;
}

.adminProductTextInput {
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  color: var(--color-text);
  background: inherit;
  border: 1px solid var(--color-text-lighter);
  border-radius: 3px;
  padding: 0px 8px;
  height: 17px;
  width: 100%;
  margin: 2px 0 0 0;
}

.adminProductTextInputLayout {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 13px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  margin: 4px 0 0 0;
}

.adminProductActionsLayout > .adminProductTextInputLayout {
  margin: 4px 8px 0 0;
}

.adminProductTextInputLayout > p {
  width: max-content;
  margin: 1px 8px auto 0px;
  white-space: nowrap;
  width: 178px;
}

.adminProductActionsLayout > .adminProductTextInputLayout > p {
  width: 100px;
}

.adminProductTextInputLayout > form + p {
  width: max-content;
  text-align: right;
  font-size: 15px;
  font-weight: 500;
  margin: 0px 8px auto 4px;
}

.adminProductBottomLayout {
  display: flex;
  flex-direction: column;
  margin: 6px 0 0;
}

.adminProductButtonsLayout {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
  margin: 10px 0 0 0;
}

.addImageButtons {
  position: absolute;
  display: flex;
  flex-direction: row;
  background: var(--color-dark);
  border-radius: 3px 0 3px 0;
}

.addImageButton {
  display: flex;
  flex-direction: row;
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 13px;
  font-weight: 500;
  color: var(--color-text-lighter);
  text-align: center;
  margin: 0;
  padding: 1px 0px 1px 8px;
  cursor: pointer;
  transition: color 0.2s cubic-bezier(0.19, 1, 0.22, 1);
}

.addImageButton:last-child {
  padding: 1px 8px;
}

.addImageButton > hr {
  width: 1px;
  height: 65%;
  background-color: var(--color-text-mid);
  margin: 0 0 0 8px;
  align-self: center;
}

.addImageButton:hover {
  color: var(--color-text-light);
}

.addImageButtonSelected {
  font-weight: 700;
  color: var(--color-white);
}

.switchLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  height: 45px;
  right: calc((100vw - 810px) / 2 + 20px);
}

.switchLabel > span {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 13px;
  font-weight: 500;
  color: var(--color-text);
  margin: auto 5px auto 5px;
}

.productEntryOuterHeader {
  display: flex;
  flex-direction: row;
}

.productFileInput {
  display: flex;
  flex-direction: column;
}

.productFileInput > input {
  display: none;
}

.productFileInput > label {
  display: flex;
}

.adminEntryImage {
  width: 135px;
  height: auto;
  background-color: var(--color-background);
  object-fit: contain;
  align-self: flex-start;
  border-radius: 3px;
  transition: opacity 1.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.adminEntryImage[alt]::after {
  display: block;
  position: absolute;
  top: 16px;
  left: 16px;
  width: 135px;
  height: 135px;
  background-color: inherit;
  font-family: 'Helvetica';
  font-weight: 300;
  line-height: 2;  
  text-align: center;
  content: attr(alt);
}

.adminEntryImage:hover {
  opacity: 0.75;
}

.adminProductOnlyLinked {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 13px;
  font-weight: 400;
  color: var(--color-text);
  width: max-content;
  margin: 4px 10px 4px auto;
  display: flex;
  flex-direction: row;
}

.adminProductOnlyLinked > span {
  margin: 0 5px 1px;
}

.adminProductOnlyLinked > input {
  margin: 4px 0 auto;
}

.filepathItemText {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  color: var(--color-text);
  background: inherit;
  width: 100%;
  border: 1px solid var(--color-text-lighter);
  border-radius: 3px;
  margin: 2px 0 0 0;
  padding: 0px 8px;
}

.adminMeditationsKey {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: var(--color-text);
  border: 1px dashed var(--color-text-lighter);
  border-radius: 3px;
  padding: 0px 8px;
}
