/* :root {
} */

.orderPageContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 30px 0;
}


.orderPageLeftLayout {
  display: flex;
  flex-direction: column;
  margin: 0px 50px;
  align-items: center;
}

.orderPageRightLayout {
  display: flex;
  flex-direction: column;
  margin: 12px 50px 30px;
  width: 400px;
  color: var(--color-text);
}

.orderPageSummary {
  background: var(--color-background);
  color: var(--color-text);
  border-radius: 10px;
  padding: 12px 20px 20px 20px;
  box-shadow: 0 4px 12px var(--color-shadow-light);
  display: flex;
  flex-direction: column;
  width: 530px;
  position: relative;
  transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);
}

.orderPageSummaryTitle {
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  width: 100%;
  margin: 0;
}

.orderPageSummaryTitle + hr {
  height: 2px;
  background-color: var(--color-text);
  margin: 12px 0 20px;
  padding: 0;
}

.orderPageCheckbox {
  margin: auto 10px;
}

.orderPageForm {
  display: flex;
  flex-direction: column;
  width: 400px;
}

.orderPageForm > label {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 12px;
  font-weight: 600;
  color: var(--color-text);
  margin: 0;
}

.orderPageInputBox {
  font-family: var(--font-input);
  font-size: 14px;
  font-weight: 400;
  border: 1px solid var(--color-text);
  border-radius: 3px;
  padding: 3px 8px;
  margin: 0 0 15px;
  color: var(--color-text);
  background: var(--color-background);
  text-align: left;
}

.orderPageNotice {
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 16px;
  font-weight: 400;
  color: var(--color-text);
  margin: 15px 0px 30px;
}

.orderPageAddressSuggestions {
  font-family: var(--font-input);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.025em;
  color: var(--color-text);
  margin: -16px 0px 0px;
  border: 1px solid var(--color-text);
  border-radius: 3px;
}

.orderPageAddressSuggestionEntry {
  /* margin: 0px 0 8px; */
  margin: 0;
  padding: 4px 10px;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1);
  transition-property: background-color, color;
}

.orderPageAddressSuggestionEntry:hover {
  background-color: var(--color-text);
  color: var(--color-background);
}

.orderPageCgvAcceptation {
  width: max-content;
  margin: 0 auto 1em 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.orderPageCgvAcceptation > label {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 13px;
  font-weight: 400;
  color: var(--color-text);
  margin: 0 6px;
}

.orderPageCgvAcceptation > label > a {
  color: var(--color-link);
  cursor: pointer;
}

.orderPageCgvAcceptation > label > a:hover {
  color: var(--color-link-alt);
}

.orderPageCgvAcceptation > input {
  margin: auto 0;
}

@media screen and (max-width: 1200px) {
  .orderPageContent {
    flex-direction: column;
  }

  .orderPageRightLayout {
    align-self: center;
    margin-top: 30px;
  }
}

@media screen and (max-width: 768px) {
  .orderPageContent {
    margin: 60px 0;
  }

  .orderPageNotice {
    font-size: 14px;
  }

  .orderPageSummary {
    width: calc(90vw - 20px * 2);
  }

  .ordersEntry {
    flex-direction: column;
  }

  .orderPageRightLayout {
    margin: 12px 20px 30px;
    width: 88vw;
  }

  .orderPageForm {
    width: 88vw;
  }
}
