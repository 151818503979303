:root {
  --color-text: #E6E6E6;
  --color-text-mid: #C0C0C0;
  --color-text-light: #B0B0B0;
  --color-text-lighter: #666;
  --color-text-white: #222021;
  --color-background: #282627;
  --color-white: #FFF;
  --color-dark: #222;
  --color-link: #6290f3;
  --color-link-alt: #2d53a5;
  --color-success: #16a765;
  --color-error: #f51d35;
  --color-info: #4c66ca;
  --color-hover: #4e4f52;
  --color-shadow-lighter: rgba(0, 0, 0, 0.25);
  --color-shadow-light: rgba(0, 0, 0, 0.35);
  --color-shadow-mid: rgba(0, 0, 0, 0.5);
  --color-shadow-dark: rgba(0, 0, 0, 0.88);
  --color-background-light: rgba(0, 0, 0, 0.16);
  --color-background-dark: rgba(0, 0, 0, 0.54);
  --color-background-darker: rgba(0, 0, 0, 0.7);
  --font-text: 'BellrosePro', serif;
  --font-text-letter-spacing: 0.03em;
  --font-title: 'Rubik', sans-serif;
  --font-title-letter-spacing: 0.0em;
  --font-input: 'Space Mono', monospace;
}

/*
Misc
*/
a {
  text-decoration: none;
  color: var(--color-text);
}

hr {
  border: none;
}

div.modalOpened {
  position: fixed;
  overflow-y: hidden;
}

svg line {
  stroke: var(--color-text);
}

/*
Boutons
*/
.buttonPrimaryAction {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.02em;
  text-align: center;
  padding: 3px 8px;
  margin: auto 0 23px 0;
  border: none;
  border-radius: 3px;
  color: var(--color-text-white);
  background-color: var(--color-text);
  transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  /* not selectable */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.buttonPrimaryAction:hover {
  cursor: pointer;
  transform: translateY(1px);
}

.buttonSecondaryAction {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 13px;
  font-weight: 400;
  position: absolute;
  bottom: 0;
  width: fit-content;
  padding: 2px 17px;
  margin: 18px 0;
  border: 1px solid var(--color-text);
  border-radius: 3px;
  color: var(--color-text);
  background-color: var(--color-background);
  transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  /* not selectable */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.buttonSecondaryAction:hover {
  cursor: pointer;
  transform: scale(1.03);
}

.buttonDisabled {
  cursor: default;
  border: 1px solid var(--color-text-light);
  color: var(--color-text-light);
  pointer-events: none;
  /* not selectable */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.buttonError {
  cursor: default;
  border: 1px solid var(--color-error);
  color: var(--color-error);
  font-weight: 500;
  pointer-events: none;
  /* not selectable */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.buttonSuccess {
  cursor: default;
  border: none;
  color: #fff;
  background: var(--color-success);
  font-weight: 500;
  pointer-events: none;
  /* not selectable */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*
Modal windows
*/
/*!Fallback (pour Firefox principalement)*/
.backgroundBlur {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  /* height: 100vh; */
  height: calc(100vh - 40px);
  padding: 20px 0;
  z-index: 10000;
  overflow: auto;
  background-color: var(--color-background-dark);
  display: flex;
}

@supports ((-webkit-backdrop-filter: blur(3px)) or (backdrop-filter: blur(3px))) {
  .backgroundBlur {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    /* height: 100vh; */
    height: calc(100vh - 40px);
    padding: 20px 0;
    z-index: 10000;
    overflow: auto;
    background-color: var(--color-background-dark);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    display: flex;
  }
}

.backgroundDark {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  overflow: auto;
  background-color: var(--color-background-light);
  display: flex;
}

.modalWindow {
  position: relative;
  border-radius: 5px;
  margin: auto;
  background-color: var(--color-background);
  box-shadow: 0px 5px 16px var(--color-shadow-mid);
  z-index: 12;
}

.modalTitle {
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 22px;
  font-weight: 700;
  margin: 10px auto;
  text-align: center;
  color: var(--color-text);
}

.altModalWindow {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 10px 34px var(--color-shadow-dark);
  z-index: 15;
  border-radius: 5px;
  background-color: var(--color-background);
}

.modalListHeader {
  background: var(--color-background);
  width: 100%;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 2px 6px var(--color-shadow-light);
}

.modalListFooter {
  background: var(--color-background);
  border-radius: 0 0 5px 5px;
  padding: 12px 20px;
  box-shadow: 0 -2px 6px var(--color-shadow-light);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.modalListEntry {
  color: var(--color-text);
  background: var(--color-background);
  border-radius: 0 0 10px 10px;
  padding: 30px 20px 20px 20px;
  margin-top: -10px;
  box-shadow: 0 4px 12px var(--color-shadow-light);
  display: flex;
  flex-direction: row;
  position: relative;
  transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);
}

.modalListEntry:first-of-type {
  padding: 20px;
  margin-top: 0px;
}

.buttonConfirmAction {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 13px;
  font-weight: 600;
  position: relative;
  align-self: flex-start;
  width: fit-content;
  padding: 3px 20px 2px;
  margin: 0 0 0 18px;
  border-radius: 3px;
  color: var(--color-background);
  cursor: pointer;
  background-color: var(--color-text);
  transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  /* not selectable */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.buttonConfirmAction:hover {
  transform: scale(1.03);
}

.buttonRejectAction {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 13px;
  font-weight: 400;
  position: relative;
  align-self: flex-start;
  width: fit-content;
  padding: 2px 17px;
  margin: 0 0 0 18px;
  border: 1px solid var(--color-text);
  border-radius: 3px;
  color: var(--color-text);
  cursor: pointer;
  background-color: var(--color-background);
  transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  /* not selectable */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.buttonRejectAction:hover {
  transform: scale(1.03);
}

/*
MessageBox
*/
.messageBox {
  position: fixed;
  top: 100vh;
  /* update in js */
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin-top: 19px;
  padding: 8px 8px;
  background: var(--color-text);
  color: var(--color-text-white);
  border: none;
  border-radius: 5px;
  box-shadow: 0 5px 16px var(--color-shadow-mid);
  transition: top 0.45s cubic-bezier(0.19, 1, 0.22, 1),
    opacity 0.4s cubic-bezier(0.23, 1, 0.320, 1);
  z-index: 9999;
}

.messageBoxHidden {
  position: fixed !important;
  top: 100vh !important;
  opacity: 0;
}

.emptyMsgBoxAnchor {
  margin: 20px auto;
  width: 400px;
}

.msgBoxLoadingSpinner {
  margin: 0 10px;
  border-color: var(--color-white) transparent var(--color-white) transparent;
}

.audioPlayerLoadingSpinner {
  margin: 0 0;
  border-color: var(--color-text) transparent var(--color-text) transparent;
}

/*
Footer
*/
.footer {
  width: inherit;
  display: flex;
  flex-direction: row;
  padding: 20px 20px;
  justify-content: center;
  background: var(--color-background);
  box-shadow: 0px -3px 12px var(--color-shadow-lighter);
}

.footerCategory {
  display: flex;
  flex-direction: column;
  margin: 0 20px;
}

.footerCategory + hr {
  width: 1px;
  background: #C1C1C1;
  margin: 7px 15px;
  padding: 0;
  transform: scaleX(0.5);
}

.footerCategoryTitle {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 14px;
  font-weight: 200;
  letter-spacing: 0.02em;
  text-align: left;
  color: var(--color-text-mid);
}

.footerCategoryLink {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.02em;
  text-align: left;
  cursor: pointer;
  color: var(--color-text);
}

.footerCategoryName {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0.02em;
  text-align: left;
  color: var(--color-text-mid);
}

.footerContentToHide {
  display: flex;
  flex-direction: row;
}

.footerProfile {
  width: inherit;
  display: flex;
  flex-direction: row;
  margin-bottom: var(--audio-player-size);
  margin-top: calc(-2 * var(--meditations-card-spacing));
  padding: 20px 20px;
  justify-content: center;
  box-shadow: 0 -2px 6px var(--color-shadow-lighter);
}

.sizeGuideModalBox {
  display: flex;
  flex-direction: column;
  width: 480px;
  height: auto;
  padding: 0 34px;
}


.legalDocumentModalBox {
  display: flex;
  flex-direction: column;
  width: 630px;
  height: auto;
  padding: 0 34px;
}

.legalDocumentText {
  font-family: var(--font-text);
  letter-spacing: var(--font-text-letter-spacing);
  font-size: 16px;
  font-weight: 400;
  margin: 15px 0;
  text-align: left;
  color: var(--color-text);
}

.legalDocumentText > h1 {
  text-align: center;
  margin: 5px 0;
}

.legalDocumentText > h2 {
  text-align: left;
  margin: 15px 0;
}

.legalDocumentText > h1 + h4 {
  text-align: center;
  padding: 0;
  margin: 0px 0 20px;
}

.withdrawalFormModalBox {
  display: flex;
  flex-direction: column;
  width: 670px;
  height: auto;
  padding: 0 34px;
  color: var(--color-text);
}

.withdrawalFormModalBox > p {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 13px;
  font-weight: 400;
  margin: 0px 5px 5px;
}

.withdrawalFormModalBox > p > a {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 13px;
  font-weight: 400;
  color: var(--color-link);
  cursor: pointer;
}

.withdrawalFormModalBox > p > a:hover {
  color: var(--color-link-alt);
}

.withdrawalFormTextArea {
  font-family: var(--font-title);
  letter-spacing: var(--font-title-letter-spacing);
  font-size: 13px;
  font-weight: 400;
  margin: 5px 5px;
  border: 1px solid var(--color-text);
  border-radius: 3px;
  color: var(--color-text);
  background: var(--color-background);
}

/*
Color themes
*/
.colorError {
  background: var(--color-error);
  color: var(--color-white);
}

.colorInfo {
  background: var(--color-info);
  color: var(--color-white);
}

.colorSuccess {
  background: var(--color-success);
  color: var(--color-white);
}

.colorBorderError {
  background: var(--color-background);
  color: var(--color-error);
  border: 1px solid var(--color-error);
}


@media screen and (max-width: 768px) {
  :focus {
    outline: none !important;
  }

  .footer {
    padding: 20px 10px;
  }

  .footerCategory {
    margin: 0 10px;
  }

  .footerCategoryTitle {
    font-size: 13px;
  }

  .footerCategoryLink {
    font-size: 11px;
  }

  .footerCategoryName {
    font-size: 11px;
  }

  .footerCategory + hr {
    display: none;
  }

  .modalTitle {
    font-size: 20px;
  }

  .buttonPrimaryAction {
    font-size: 13px;
    padding: 5px 8px;
  }

  .buttonSecondaryAction {
    padding: 5px 17px;
  }

  .buttonConfirmAction {
    padding: 6px 20px 5px;
  }

  .buttonRejectAction {
    padding: 5px 17px;
  }

  .modalWindow {
    top: 0;
    transform: none;
    overflow: auto;
    height: max-content;
    margin: 2vh auto 20vh auto;
  }

  .backgroundBlur {
    overflow: auto;
  }

  .backgroundDark {
    overflow: auto;
  }

  .legalDocumentText {
    font-size: 14px;
  }
}

@media screen and (max-width: 360px) {
  .footerCategoryTitle {
    font-size: 11px;
  }

  .footerCategoryLink {
    font-size: 10px;
  }

  .footerCategoryName {
    font-size: 10px;
  }
}
