/* First spinner */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 56px;
  height: 56px;
  left: 50%;
  transform: translateX(-50%);
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--color-text);
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 24px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 40px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(16px, 0);
  }
}

/* Second spinner */
.lds-dual-ring {
  display: block;
  width: 14px;
  border-color: inherit;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 14px;
  height: 14px;
  /* margin: auto 10px; */
  margin: auto auto;
  border-radius: 50%;
  border: 2px solid;
  border-color: inherit;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
